import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors, mq } from "../styles/styles"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Trial from "../components/trial"
import Contact from "../components/contact"
import linkResolver from "../utils/linkResolver"

const AboutUsPage = ({ data, pageContext: { locale }, location }) => {
  const AboutUsPageData = data.prismic.aboutuspage
  return (
    <>
      <SEO
        title={AboutUsPageData.meta_title}
        desc={AboutUsPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: AboutUsPageData.title,
          subtitle: AboutUsPageData.subtitle,
          image: {
            image: AboutUsPageData.main_image,
            sharp: AboutUsPageData.main_imageSharp,
          },
        }}
      />
      <section className="section is-medium" css={aboutUsCss}>
        <div className="container">
          <h3 className="title has-text-centered pb-6">
            {AboutUsPageData.content_title &&
              RichText.asText(AboutUsPageData.content_title)}
          </h3>
          <div className="columns is-variable is-8">
            <div className="column is-6 has-text-justified hasRichText">
              {AboutUsPageData.content_richtext &&
                RichText.render(AboutUsPageData.content_richtext, linkResolver)}
            </div>
            <div className="columns column is-6">
              <div className="tile is-ancestor">
                <div className="tile is-parent">
                  <div className="tile is-child box">
                    {AboutUsPageData.content_image_1 && (
                      <Image
                        sharp={AboutUsPageData.content_image_1Sharp}
                        image={AboutUsPageData.content_image_1}
                      />
                    )}
                  </div>
                </div>
                <div className="tile is-4 is-vertical is-parent">
                  <div className="tile is-child box">
                    {AboutUsPageData.content_image_2 && (
                      <Image
                        sharp={AboutUsPageData.content_image_2Sharp}
                        image={AboutUsPageData.content_image_2}
                      />
                    )}
                  </div>
                  <div className="tile is-child box">
                    {AboutUsPageData.content_image_3 && (
                      <Image
                        sharp={AboutUsPageData.content_image_3Sharp}
                        image={AboutUsPageData.content_image_3}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section" css={teamCss}>
        <div className="container">
          <div className="columns">
            <div className="column is-fullwidth">
              <h3 className="title has-text-centered pb-6">
                {RichText.asText(AboutUsPageData.team_title)}
              </h3>
              {AboutUsPageData.team_subtitle && (
                <h4 className="subtitle has-text-centered">
                  {RichText.asText(AboutUsPageData.team_subtitle)}
                </h4>
              )}
            </div>
          </div>

          <div className="columns is-multiline is-centered is-vcentered">
            {AboutUsPageData.body[0].fields.map((field, i) => {
              return (
                <div key={i} className="column is-3 has-text-centered pb-6">
                  {field.team_image && (
                    <figure className="image is-184x184 is-margin-0-auto is-circular mb-4">
                      <Image
                        sharp={field.team_imageSharp}
                        image={field.team_image}
                      />
                    </figure>
                  )}
                  {field.team_name && (
                    <h5 className="title has-text-weight-medium is-size-5">
                      {RichText.asText(field.team_name)}
                    </h5>
                  )}
                  {field.team_position && (
                    <p className="subtitle has-text-weight-light">
                      <small>{RichText.asText(field.team_position)}</small>
                    </p>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <Trial trial={data.prismic.allTrialcomponents.edges[0].node} />
    </>
  )
}

export default AboutUsPage

AboutUsPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const AboutUsPageQuery = graphql`
  query AboutUsPageQuery($uid: String!, $locale: String!) {
    prismic {
      aboutuspage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        content_title
        content_richtext
        content_image_1
        content_image_1Sharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        content_image_2
        content_image_2Sharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        content_image_3
        content_image_3Sharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }

        team_title
        team_subtitle
        body {
          ... on PRISMIC_AboutuspageBodyTeam {
            fields {
              team_name
              team_position
              team_image
              team_imageSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
      ...Trial
    }

    site {
      ...SiteInformation
    }
  }
`

const aboutUsCss = css`
  .box {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }

  .tile.is-child.box {
    img {
      object-fit: cover !important;
    }
  }
`

const teamCss = css`
  .is-circular {
    border-radius: 50%;
  }
`
